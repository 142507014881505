import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { List } from 'react-bootstrap-icons';

import Link from './link';

import logo from '../../images/framework/logo.svg';

const Header = () => {
	const { t } = useI18next();

	return (
		<>
			<nav className="header navbar navbar-expand-lg bg-transparent navbar-light bg-cream navbar-bg">
				<div className="container-fluid">
					<Link className="navbar-brand me-auto" to="/">
						<img alt="Zample logo" className="d-inline" src={logo} />
					</Link>

					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNavAltMarkup"
						aria-controls="navbarNavAltMarkup"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<List />
					</button>
					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav mt-3 mt-md-0 ms-auto rounded px-2 py-1">
							<Link
								className="nav-link me-3"
								aria-current="page"
								to="/locations"
							>
								{t('component_header:t1')}
							</Link>
							<Link aria-current="page" className="nav-link me-3" to="/prices">
								{t('component_header:t2')}
							</Link>
						
							<Link aria-current="page" className="nav-link me-3" to="/support">
								{t('component_header:t4')}
							</Link>
							<Link aria-current="page" className="nav-link me-3" to="/contact">
								{t('component_header:t5')}
							</Link>
							<Link
								aria-current="page"
								className="nav-link text-primary"
								to="/book"
							>
								{t('component_header:t6')}
							</Link>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Header;
