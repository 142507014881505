import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Contact from './contact';
import Link from './link';

const Footer = () => {
	const { t } = useI18next();

	return (
		<footer>
			<div className="container-fluid bg-white mt-0 py-4">
				<div className="container">
					<div className="row">
						<div className="col-md-4 mt-4">
							<Contact />
						</div>

						<div className="row justify-content-between pt-4">
							<div className="col-md-6">
								<p className="x-small text-secondary mb-0 d-block">
									{t('component_footer:t2')}
								</p>
							</div>
							<div className="col-auto">
								<Link
									aria-current="page"
									className="x-small"
									to="/privacy-policy"
								>
									{t('component_footer:t3')}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
