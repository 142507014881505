/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'gatsby-plugin-react-i18next';
function Seo({ description, image, tags, title }) {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						description
						siteUrl
						title
					}
				}
			}
		`
	);

	const logoUrl = `${site.siteMetadata.siteUrl}/assets/images/seo/logo.jpg`;
	const imageUrl = image ? `${site.siteMetadata.siteUrl}${image}` : logoUrl;
	const metaDescription = description || site.siteMetadata.description;
	const pageUrl = `${site.siteMetadata.siteUrl}${pathname}`;
	const schemaOrganization = {
		'@context': 'https://schema.org',
		'@type': 'Organization',
		name: site.siteMetadata.title,
		url: site.siteMetadata.siteUrl,
		logo: logoUrl,
		sameAs: [],
	};

	return (
		<Helmet titleTemplate={`%s | ${site.siteMetadata.title}`}>
			<title>{title}</title>
			<link href={pageUrl} rel="canonical" />
			<meta content={metaDescription} name="description" />
			<meta content={tags} name="keywords" />

			<meta content="494078745373596" property="fb:app_id" />
			<meta content={metaDescription} property="og:description" />
			{imageUrl && <meta content={imageUrl} property="og:image" />}
			<meta content={site.siteMetadata.title} property="og:site_name" />
			<meta content={title} property="og:title" />
			<meta content="website" property="og:type" />
			<meta content={pageUrl} property="og:url" />

			<meta content="summary" name="twitter:card" />
			<meta content={site.siteMetadata.author} name="twitter:creator" />
			<meta content={metaDescription} name="twitter:description" />
			{imageUrl && <meta content={imageUrl} name="twitter:image" />}
			<meta content={title} name="twitter:title" />

			<script type="application/ld+json">
				{JSON.stringify(schemaOrganization)}
			</script>
			<script
				async
				src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
				type="text/javascript"
			></script>
		</Helmet>
	);
}

Seo.defaultProps = {
	description: '',
};

Seo.propTypes = {
	description: PropTypes.string,
	image: PropTypes.string,
	tags: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default Seo;
