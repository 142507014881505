import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { TelephoneFill } from 'react-bootstrap-icons';
import { EnvelopeFill } from 'react-bootstrap-icons';

const Contact = () => {
	const result = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					email
					phone_number
				}
			}
		}
	`);
	const { email, phone_number } = result.site.siteMetadata;

	return (
		<>
			<section>
				{phone_number && (
					<div className="row mb-1">
						<div className="col-auto pe-0">
							<div className="title-small text-primary container-icon text-center">
								<TelephoneFill />
							</div>
						</div>
						<div className="col">
							<span className="mb-0">
								<a href={`tel:${phone_number}`}>{phone_number}</a>
							</span>
						</div>
					</div>
				)}

				{email && (
					<div className="row">
						<div className="col-auto pe-0">
							<div className="title-small text-primary container-icon text-center">
								<EnvelopeFill />
							</div>
						</div>
						<div className="col">
							<span className="mb-0">
								<a href={`mailto:${email}`}>{email}</a>
							</span>
						</div>
					</div>
				)}
			</section>
		</>
	);
};

export default Contact;
