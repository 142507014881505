import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Link from './link';

import flagCImode from '../../images/flags/cimode.svg';
import flagEN from '../../images/flags/en.svg';
import flagNL from '../../images/flags/nl.svg';
import flagSV from '../../images/flags/sv.svg';

const Language = () => {
	const { language, languages, originalPath } = useI18next();
	const flags = { cimode: flagCImode, en: flagEN, nl: flagNL, sv: flagSV };

	return (
		<div className="p-1" id="language">
			{languages.map((key) => (
				<span key={key}>
					{language != key && (
						<Link className="hidden" language={key} to={originalPath}>
							<img alt={`Flag ${key}`} src={flags[key]} />
						</Link>
					)}
				</span>
			))}

			<Link className="d-block" language={language} to={originalPath}>
				<img alt={`Flag ${language}`} src={flags[language]} />
			</Link>
		</div>
	);
};

export default Language;
