import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

import Alert from './alert';
import Footer from './footer';
import Header from './header';
import Language from './language';

const Layout = ({ children, header, location }) => {
	const { t } = useI18next();

	return (
		<>
			<Header header={header} location={location} />
			{children}
			<Footer />
			<Language />
			<CookieConsent
				buttonClasses="btn btn-primary bg-primary-light text-primary"
				buttonWrapperClasses="me-2 mb-3 p-2"
				buttonText={t('component_framework:t1')}
				disableButtonStyles
				expires={999}
				location="bottom"
				overlay
				overlayStyle={{ zIndex: 99999999 }}
			>
				{t('component_framework:t2')}
			</CookieConsent>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.array,
	header: PropTypes.object,
	location: PropTypes.object.isRequired,
};

export default Layout;
